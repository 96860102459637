import * as React from "react";

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import FormsTableHead from "./FormsTableHead";
import SeverityPill from "../SeverityPill";

import { getComparator } from "./utils";
import { Order } from "./utils";

import { formatPhoneNumber, updateUserRole } from "../../utils/users";
import { FirebaseReleaseForm } from "../../types/form";

type FormsTableProps = {
    forms: FirebaseReleaseForm[];
};

export default function FormsTable(props: FormsTableProps) {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] =
        React.useState<keyof FirebaseReleaseForm>("student_first");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof FirebaseReleaseForm
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectChange = (event: SelectChangeEvent, user_id: string) => {
        updateUserRole(user_id, event.target.value);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - props.forms.length)
            : 0;

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                    >
                        <FormsTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.forms.length}
                        />
                        <TableBody>
                            {props.forms
                                .slice()
                                .sort(getComparator(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((f, index) => {
                                    const labelId = `user-table-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={f.id}
                                        >
                                            <TableCell align="left">
                                                {f.id}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {f.student_first}
                                            </TableCell>
                                            <TableCell align="left">
                                                {f.student_last}
                                            </TableCell>
                                            <TableCell align="left">
                                                {f.parent_first}
                                            </TableCell>
                                            <TableCell align="left">
                                                {f.parent_last}
                                            </TableCell>
                                            <TableCell align="left">
                                                {f.school_name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.forms.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </Box>
    );
}
