import { Box, Button, Drawer, useMediaQuery } from "@mui/material";
import DashboardNavItem from "./DashboardNavItem";
import DashboardSidebarFooter from "./DashboardSidebarFooter";
import HomeIcon from "@mui/icons-material/Home";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import EmailIcon from "@mui/icons-material/Email";

import logo from "../../assets/scioly_logo.png";
import { logOut } from "../../utils/authentication";
import { Roles } from "../../types/roles";
import { useAppSelector } from "../../redux/hooks";

const items = [
    {
        href: "/",
        icon: <HomeIcon fontSize="small" />,
        title: "HOME",
        roles: [Roles.admin, Roles.coach],
    },
    {
        href: "/roster",
        icon: <FormatListBulletedIcon fontSize="small" />,
        title: "ROSTER",
        roles: [Roles.admin, Roles.coach],
    },
    {
        href: "/selfschedule",
        icon: <ScheduleIcon fontSize="small" />,
        title: "SELF SCHEDULE",
        roles: [Roles.admin, Roles.coach],
    },
    {
        href: "/users",
        icon: <PeopleIcon fontSize="small" />,
        title: "USERS",
        roles: [Roles.admin],
    },
    {
        href: "/schools",
        icon: <SchoolIcon fontSize="small" />,
        title: "SCHOOLS",
        roles: [Roles.admin],
    },
    {
        href: "/invoice",
        icon: <ReceiptIcon fontSize="small" />,
        title: "INVOICE",
        roles: [Roles.admin],
    },
    // {
    //     href: "/email",
    //     icon: <EmailIcon fontSize="small" />,
    //     title: "EMAIL",
    //     roles: [Roles.admin],
    // },
    {
        href: "/release_forms",
        icon: <AssignmentIndIcon fontSize="small" />,
        title: "RELEASE FORMS",
        roles: [Roles.admin],
    },
    {
        href: "/tournament_setup",
        icon: <CreateNewFolderIcon fontSize="small" />,
        title: "SETUP",
        roles: [Roles.admin],
    },
    {
        href: "/awards_slides_creator",
        icon: <SlideshowIcon fontSize="small" />,
        title: "SLIDES CREATOR",
        roles: [Roles.admin],
    },
    {
        href: "/settings",
        icon: <SettingsIcon fontSize="small" />,
        title: "SETTINGS",
        roles: [Roles.admin, Roles.coach],
    },
];

export const DashboardSidebar = (props: any) => {
    const { open, onClose } = props;
    const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"), {
        defaultMatches: true,
        noSsr: false,
    });
    const userRole = useAppSelector((state) => state.user.user.role);

    const content = (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        mt: 2,
                        mb: 2,
                        mx: "auto",
                        width: "120px",
                        "& img": {
                            width: "100%",
                        },
                    }}
                >
                    <img src={logo} alt="MIT Science Olympiad Logo" />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        mt: 2,
                    }}
                >
                    {items
                        .filter((e) => e.roles.includes(userRole))
                        .map((item) => (
                            <DashboardNavItem
                                key={item.title}
                                icon={item.icon}
                                href={item.href}
                                title={item.title}
                                onClose={props.onClose}
                            />
                        ))}
                </Box>
                <Box
                    sx={{
                        px: 2,
                        py: 3,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Button
                        color="secondary"
                        sx={{ mt: 2 }}
                        variant="contained"
                        onClick={logOut}
                    >
                        Log Out
                    </Button>
                    <DashboardSidebarFooter />
                </Box>
            </Box>
        </>
    );

    const menuWidth = 240;

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: "bg.sidebar",
                        color: "#FFFFFF",
                        width: menuWidth,
                        border: "none",
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={!open}
            PaperProps={{
                sx: {
                    backgroundColor: "bg.sidebar",
                    color: "#FFFFFF",
                    width: menuWidth,
                    border: "none",
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};
