import { FirebaseReleaseForm } from "../../types/form";

export type Order = "asc" | "desc";

type HeadCell = {
    disablePadding: boolean;
    id: keyof FirebaseReleaseForm;
    label: string;
    numeric: boolean;
    sortable: boolean;
};

export const headCells: readonly HeadCell[] = [
    {
        id: "id",
        numeric: false,
        disablePadding: false,
        label: "id",
        sortable: true,
    },
    {
        id: "student_first",
        numeric: false,
        disablePadding: true,
        label: "Student First",
        sortable: true,
    },
    {
        id: "student_last",
        numeric: false,
        disablePadding: true,
        label: "Student Last",
        sortable: true,
    },
    {
        id: "parent_first",
        numeric: false,
        disablePadding: true,
        label: "Parent First",
        sortable: true,
    },
    {
        id: "parent_last",
        numeric: false,
        disablePadding: true,
        label: "Parent Last",
        sortable: false,
    },
    {
        id: "school_name",
        numeric: false,
        disablePadding: true,
        label: "School",
        sortable: true,
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
