import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    TextField,
    Typography,
    Grid,
    MenuItem,
} from "@mui/material";
import { AccessCode } from "../../types/authentication";
import { School } from "../../types/school";
import { addAccessCode } from "../../utils/setup";

type AddAccessCodeProps = {
    schools: School[];
};

export default function AddAccessCode(props: AddAccessCodeProps) {
    const [open, setOpen] = useState(false);

    const getFormData = (e: React.SyntheticEvent<HTMLFormElement>) => {
        const data = new FormData(e.currentTarget);
        return {
            email: String(data.get("email")).trim(),
            code: String(data.get("code")).trim(),
            school: String(data.get("school")).trim(),
        };
    };

    const initialError = {
        email: "",
        code: "",
        school: "",
    };

    const [formErrors, setFormErrors] = useState(initialError);

    const validateForm = (data: AccessCode) => {
        let result = true;
        let newErrors = { ...formErrors };
        if (data.email === "") {
            newErrors.email = "Please enter a valid email.";
            result = false;
        }
        if (data.code === "") {
            newErrors.code = "Please enter an access code.";
            result = false;
        }
        if (data.school === "") {
            newErrors.school = "Please select a school.";
            result = false;
        }
        setFormErrors(newErrors);
        return result;
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = getFormData(e);
        const new_code = {
            ...data,
        };
        if (validateForm(data)) {
            try {
                addAccessCode(new_code, setOpen);
                console.log(new_code);
            } catch (error) {
                console.log(error);
            }
        }
        console.log("Add code");
    };

    const handleClose = () => {
        setOpen(false);
        setFormErrors(initialError);
    };

    return (
        <>
            <Button variant={"contained"} onClick={() => setOpen(true)}>
                Add Code
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    my={3}
                    mx={3}
                >
                    <Typography variant="h5" mb={3}>
                        Add Access Code
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="School"
                                select
                                fullWidth
                                required
                                id="school"
                                name="school"
                                defaultValue={""}
                                helperText={formErrors.school}
                                error={formErrors.school !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            school: "",
                                        });
                                    }
                                }}
                            >
                                {props.schools.map((school) => (
                                    <MenuItem key={school.id} value={school.id}>
                                        {school.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                helperText={formErrors.email}
                                error={formErrors.email !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            email: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                fullWidth
                                id="code"
                                label="Access Code"
                                name="code"
                                type="text"
                                helperText={formErrors.code}
                                error={formErrors.code !== ""}
                                FormHelperTextProps={{
                                    sx: { fontSize: 14 },
                                }}
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setFormErrors({
                                            ...formErrors,
                                            code: "",
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        pt={3}
                        sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        <Button variant="contained" type="submit" autoFocus>
                            <Typography variant="body1" color="neutral.white">
                                Add
                            </Typography>
                        </Button>
                        <Button onClick={handleClose}>
                            <Typography
                                variant="body1"
                                color="neutral.secondary"
                            >
                                Cancel
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}
