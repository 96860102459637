import React from "react";
import {
    collection,
    doc,
    query,
    where,
    onSnapshot,
    addDoc,
    deleteDoc,
    writeBatch,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import store from "../redux/store";
import { setError, clearError } from "../redux";
import { getFunctions, httpsCallable } from "firebase/functions";

import { NewStudent, Student, UpdateStudent } from "../types/student";

export const getStudentsAndSetState = async (
    setStudents: React.Dispatch<React.SetStateAction<Student[]>>,
    school_id: string
) => {
    const userQuery = query(
        collection(db, "students"),
        where("school", "==", school_id)
    );

    try {
        await onSnapshot(userQuery, (querySnapshot) => {
            setStudents(
                querySnapshot.docs.map(
                    (doc) =>
                        ({
                            id: doc.id,
                            ...doc.data(),
                        } as Student)
                )
            );
        });
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const deleteStudent = async (student: Student) => {
    const batch = writeBatch(db);
    batch.delete(doc(db, "students", student.id));
    if (student.release_form !== "") {
        const release_form_to_update_ref = doc(
            db,
            "release_forms",
            student.release_form
        );
        batch.update(release_form_to_update_ref, { matched_student: "" });
    }
    try {
        await batch.commit();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteSelectedStudents = async (students: Student[]) => {
    const batch = writeBatch(db);
    students.forEach((s) => {
        batch.delete(doc(db, "students", s.id));
        if (s.release_form !== "") {
            const release_form_to_update_ref = doc(
                db,
                "release_forms",
                s.release_form
            );
            batch.update(release_form_to_update_ref, { matched_student: "" });
        }
    });
    try {
        await batch.commit();
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    }
};

const teamWillBeValid = (
    new_student: NewStudent | UpdateStudent,
    students_in_team: Student[]
) => {
    if (new_student.alternate) {
        if (students_in_team.filter((s) => s.alternate).length >= 5) {
            // if new_student is just an update to an existing alternate student
            if ("id" in new_student) {
                const old_data = students_in_team.filter(
                    (s) => s.id === new_student.id
                )[0];
                if (old_data.alternate) {
                    return [true, ""];
                }
            }
            return [false, "You cannot have more than 5 alternates."];
        } else {
            return [true, ""];
        }
    } else {
        if (students_in_team.filter((s) => !s.alternate).length >= 15) {
            // if new_student is just an update to an existing non-alternate student
            if ("id" in new_student) {
                const old_data = students_in_team.filter(
                    (s) => s.id === new_student.id
                )[0];
                if (!old_data.alternate) {
                    return [true, ""];
                }
            }
            return [
                false,
                "You cannot have more than 15 non-alternate competitors.",
            ];
        } else if (
            students_in_team.filter((s) => s.grade === 12 && !s.alternate)
                .length >= 7 &&
            new_student.grade === 12 &&
            !new_student.alternate
        ) {
            // if new_student is just an update to an existing non-alternate senior
            if ("id" in new_student) {
                const old_data = students_in_team.filter(
                    (s) => s.id === new_student.id
                )[0];
                if (!old_data.alternate && old_data.grade === 12) {
                    return [true, ""];
                }
            }
            return [
                false,
                "You cannot have more than 7 non-alternate seniors in your team.",
            ];
        } else {
            return [true, ""];
        }
    }
};

const functions = getFunctions();
const matchStudentToReleaseFormCF = httpsCallable(
    functions,
    "matchStudentToReleaseForm"
);

export const addStudent = async (
    student: NewStudent,
    students_in_team: Student[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const [teamValidity, message] = teamWillBeValid(student, students_in_team);
    if (!teamValidity) {
        store.dispatch(setError(message as string));
    } else {
        try {
            const docRef = await addDoc(collection(db, "students"), {
                ...student,
                created_at: Timestamp.now(),
            });
            // Trigger cloud function to try to match new student to a release form
            matchStudentToReleaseFormCF({
                ...student,
                id: docRef.id,
            }).then((message) => {
                console.log(message);
            });
            setOpen(false);
        } catch (error: any) {
            let message = "";
            switch (error.code) {
                default: {
                    message = error.message;
                    break;
                }
            }
            store.dispatch(setError(message));
            console.error(error);
            throw error;
        }
    }
};

export const editStudent = async (
    student: UpdateStudent,
    student_id: string,
    students_in_team: Student[],
    release_form: string
) => {
    const [teamValidity, message] = teamWillBeValid(student, students_in_team);
    if (!teamValidity) {
        store.dispatch(setError(message as string));
    } else {
        const batch = writeBatch(db);
        if (release_form !== "") {
            const release_form_to_update_ref = doc(
                db,
                "release_forms",
                release_form
            );
            batch.update(release_form_to_update_ref, { matched_student: "" });
        }
        batch.update(doc(db, "students", student_id), {
            ...student,
            release_form: "",
        });
        try {
            await batch.commit();
            // Trigger cloud function to try to match new student to a release form
            matchStudentToReleaseFormCF({
                ...student,
            }).then((resp) => {
                console.log(resp);
            });
        } catch (error: any) {
            let message = "";
            switch (error.code) {
                default: {
                    message = error.message;
                    break;
                }
            }
            store.dispatch(setError(message));
            console.error(error);
            throw error;
        }
    }
};

// TODO: this is deprecated; should be removed
export const toggleFormStatus = async (
    studentId: string,
    newStatus: string
) => {
    const ref = doc(db, "students", studentId);

    let data_to_save = {
        release_form: newStatus,
    };

    try {
        await updateDoc(ref, data_to_save);
    } catch (error) {
        console.error(error);
    }
};
