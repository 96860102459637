export type ImportedReleaseForm = {
    id: string;
    school_name: string | null;
    school: string | null;
    student_first: string;
    student_last: string;
    parent_email: string;
    parent_first: string;
    parent_last: string;
    completion_date: string;
    matched_student: string;
};

export const DocuSignCSVMapping2024: { [index: string]: string } = {
    id: "envelopeId",
    school_name: "recipients[0].tabs.Text e596ef36-2223-4a79-ae30-15f2490af9a9",
    student_first:
        "recipients[0].tabs.Text 68aa83c3-d1f9-4331-8fc3-6c6da7158649",
    student_last:
        "recipients[0].tabs.Text 3cc2a130-554f-48ec-a30b-e1cad0427373",
    parent_email: "recipients[0].email",
    parent_first:
        "recipients[0].tabs.Text 232a24ae-0755-459d-82b7-cc1c5e369cb3",
    parent_last: "recipients[0].tabs.Text d92bcfdc-7c55-4d03-b8f4-788adfa60d58",
    completion_date: "completeDate",
};

export const DocuSignCSVMapping2025: { [index: string]: string } = {
    id: "envelopeId",
    school_name: "recipients[0].tabs.Text e596ef36-2223-4a79-ae30-15f2490af9a9",
    student_first:
        "recipients[0].tabs.Text 68aa83c3-d1f9-4331-8fc3-6c6da7158649",
    student_last:
        "recipients[0].tabs.Text 3cc2a130-554f-48ec-a30b-e1cad0427373",
    parent_email: "recipients[0].email",
    parent_first:
        "recipients[0].tabs.Text 232a24ae-0755-459d-82b7-cc1c5e369cb3",
    parent_last: "recipients[0].tabs.Text d92bcfdc-7c55-4d03-b8f4-788adfa60d58",
    completion_date: "completeDate",
};

export type FirebaseReleaseForm = {
    id: string;
    student_first: string;
    student_last: string;
    matched_student: string;
    school: string;
    parent_email: string;
    parent_first: string;
    parent_last: string;
    completion_date: string;
    school_name: string;
};
