import React, { useContext, useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import UsersTable from "../components/users/UsersTable";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

import { User } from "../types/users";
import { School } from "../types/school";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    getDocs,
} from "firebase/firestore";
import { db } from "../firebase";

function Users() {
    const [users, setUsers] = useState<User[]>([]);
    const [loadingUsers, setLoadingUsers] = useState(true);

    useEffect(() => {
        setLoadingUsers(true);
        const userQuery = query(collection(db, "users"), orderBy("last_name"));
        const schoolQuery = query(collection(db, "schools"));

        // getDocs is sufficient since we are really looking for the school name and id pair
        getDocs(schoolQuery).then((schoolArray) => {
            const schools = schoolArray.docs.map(
                (doc) => ({ id: doc.id, ...doc.data() } as School)
            );
            onSnapshot(userQuery, (querySnapshot) => {
                setUsers(
                    querySnapshot.docs.map(
                        (doc) =>
                            ({
                                id: doc.id,
                                school_name: schools.find(
                                    (e) => e.id === doc.data().school
                                )?.name,
                                ...doc.data(),
                            } as User)
                    )
                );
                setLoadingUsers(false);
            });
        });
    }, []);

    useEffect(() => {}, []);

    return (
        <>
            <DashboardLayout>
                <Box
                    component="main"
                    sx={{
                        pb: 3,
                        px: 3,
                        maxWidth: 1200,
                        mx: "auto",
                    }}
                >
                    <Typography variant="h3" py="12pt" color="white">
                        Users
                    </Typography>
                    <Container component={motion.div} variants={cardVariant}>
                        <Box>
                            <UsersTable users={users} />
                        </Box>
                    </Container>
                </Box>
            </DashboardLayout>
        </>
    );
}

export default Users;
