import React, { useState, useEffect } from "react";
import RosterTable from "../components/roster/RosterTable";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { motion } from "framer-motion";
import { cardVariant } from "../components/animation/AnimatedPageWrapper";

import { Box, Container, Typography } from "@mui/material";

export default function Roster() {
    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    pb: 3,
                    px: 3,
                    maxWidth: 900,
                    mx: "auto",
                }}
            >
                <Typography variant="h3" py="12pt" color="white">
                    Team Roster
                </Typography>
                <Box pb={2} px={3}>
                    <Typography variant="body1" color="primary">
                        Note: It may take up to 48 hours for release form
                        completion to update.
                    </Typography>
                </Box>
                <Container component={motion.div} variants={cardVariant}>
                    <Box>
                        <RosterTable />
                    </Box>
                </Container>
            </Box>
        </DashboardLayout>
    );
}
