import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import store from "../redux/store";
import { setError, clearError } from "../redux";
import { self_schedule_slots } from "./settings";

export const download = function (data: any) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a");

    // Passing the blob downloading url
    a.setAttribute("href", url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", "mit_selfschedule.csv");

    // Performing a download with click
    a.click();
};

export const csvmaker = function (data: { [key: string]: string }[]) {
    // Empty array for storing the values
    let csvRows = [];

    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    data.forEach((row) => {
        const values = Object.values(row).join(",");
        csvRows.push(values);
    });

    // Returning the array joining with new line
    return csvRows.join("\n");
};

type SelfScheduleRow = {
    event: string;
    slot: string;
    team_num: string;
    team: string;
    time: string;
};

export const getSchoolNamesAndTeamNum = async () => {
    const schoolsQuery = query(collection(db, "schools"));

    try {
        const schools = await getDocs(schoolsQuery).then((schoolArray) => {
            return schoolArray.docs.map((doc) => ({
                school: doc.data().name,
                team_number: doc.data().team_number,
            }));
        });

        const teams: { [key: string]: string } = {};
        schools.forEach((school) => {
            if (school.team_number.length === 1) {
                teams[school.team_number[0]] = school.school;
            } else if (school.team_number.length === 2) {
                teams[school.team_number[0]] = `${school.school} A`;
                teams[school.team_number[1]] = `${school.school} B`;
            }
        });

        return teams;
    } catch (error) {
        console.error(error);
    }
};

export const getSelfSchedules = async () => {
    const selfscheduleQuery = query(collection(db, "selfschedule"));

    try {
        const cleaned_selfschedule = await getDocs(selfscheduleQuery).then(
            (selfscheduleArray) => {
                return selfscheduleArray.docs.map((doc) => ({
                    event: doc.id,
                    slot1: doc.data().slot1,
                    slot2: doc.data().slot2,
                    slot3: doc.data().slot3,
                    slot4: doc.data().slot4,
                    slot5: doc.data().slot5,
                    slot6: doc.data().slot6,
                }));
            }
        );
        return cleaned_selfschedule;
    } catch (error) {
        console.error(error);
    }
};

const slots = ["slot1", "slot2", "slot3", "slot4", "slot5", "slot6"];

export const download_all = async () => {
    const selfschedule_data: any = await getSelfSchedules();
    const team_data: any = await getSchoolNamesAndTeamNum();

    const final_data: SelfScheduleRow[] = [];

    selfschedule_data.forEach((event: { [key: string]: string | string[] }) => {
        slots.forEach((s: string) => {
            const teams_in_slot = event[s] as string[];
            teams_in_slot.forEach((team_in_slot: string) => {
                const slot_id = parseInt(s.replace("slot", ""));
                final_data.push({
                    event: event.event as string,
                    slot: s,
                    time: self_schedule_slots[slot_id - 1].time,
                    team_num: team_in_slot,
                    team: team_data[team_in_slot],
                });
            });
        });
    });

    if (final_data.length === 0) {
        store.dispatch(setError("No self schedule signups yet!"));
        return;
    }

    const csvdata = csvmaker(final_data);
    download(csvdata);
};
