import Papa from "papaparse";
import {
    collection,
    doc,
    query,
    writeBatch,
    getDocs,
    Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import store from "../redux/store";
import { setError, setSuccess } from "../redux";

import { School } from "../types/school";
import { Student } from "../types/student";
import { ImportedReleaseForm } from "../types/form";
import { DocuSignCSVMapping } from "./settings";

const school_exceptions_2025: { [index: string]: string } = {
    "Wilbraham and Monson Academy": "Wilbraham & Monson Academy",
};

// Given a list of school name and an array of "schools" collection,
// match the name to the appropriate ID and return it.
// If school name is null or there is no match, then return null
export const matchSchoolNameToID = (
    school_name: string | null,
    schools: School[]
) => {
    if (school_name === null || school_name === "") {
        return "";
    }
    if (school_name in school_exceptions_2025) {
        school_name = school_exceptions_2025[school_name];
    }
    console.log(school_name);
    const school_id = schools.find((e) => e.name === school_name)?.id;
    return school_id ?? "";
};

// DocuSign school name data comes in the format of "first last (school_name)" due to
// baking in school info into the form through API.
// Returns the extracted school_name as a string.
export const getSchoolName = (docusign_school_column: string) => {
    // high school name is guaranteed to be in between the last instance of open and close paren
    const lastOpenParen = docusign_school_column.lastIndexOf("(");
    const lastCloseParen = docusign_school_column.lastIndexOf(")");
    const name = docusign_school_column
        .substring(lastOpenParen + 1, lastCloseParen)
        .substring(11); // finally remove "as part of"

    console.log(name);
    return name;
};

// Correctly parse and sanitize incoming csv release form data generated by DocuSign
// then set React state using given callback
export const parseDocuSignDataAndSetState = (
    inputFile: any,
    setDocuSignForms: React.Dispatch<
        React.SetStateAction<ImportedReleaseForm[]>
    >
) => {
    const allowedFiles = ["csv", "tsv"];

    const fileExtension = inputFile?.type.split("/")[1];
    if (allowedFiles.includes(fileExtension)) {
        Papa.parse(inputFile, {
            header: true,
            complete: function (results: any) {
                const data = results.data;
                const cleaned = data.map(
                    (form: { [index: string]: string }) => {
                        return {
                            id: form[DocuSignCSVMapping.id],
                            school_name: getSchoolName(
                                form[DocuSignCSVMapping.school_name]
                            ),
                            school: null,
                            student_first:
                                form[DocuSignCSVMapping.student_first].trim(),
                            student_last:
                                form[DocuSignCSVMapping.student_last].trim(),
                            parent_email:
                                form[DocuSignCSVMapping.parent_email].trim(),
                            parent_first:
                                form[DocuSignCSVMapping.parent_first].trim(),
                            parent_last:
                                form[DocuSignCSVMapping.parent_last].trim(),
                            completion_date:
                                form[DocuSignCSVMapping.completion_date],
                        } as ImportedReleaseForm;
                    }
                );
                setDocuSignForms(cleaned);
            },
        });
    } else {
        store.dispatch(setError("Setup file must be a csv or a tsv."));
    }
};

// Given a student first name, a student last name, and a school_id, return the id
// of matching student if any. If school_id is an empty string, automatically return
// empty string.
// TODO: since release forms are uploaded in batches, this will be extremely inefficient.
//       improve by somehow memoizing each school and access that way
// For now, just batch get all student info and save them in key value pair where
// key is concat of first, last, school_id and value is student_id
export const findMatchingStudent = (
    student_first: string,
    student_last: string,
    school_id: string,
    lookuptable: { [index: string]: string }
) => {
    const to_find =
        student_first.toLowerCase() + student_last.toLowerCase() + school_id;

    if (school_id === "") {
        return "";
    }
    if (to_find in lookuptable) {
        return lookuptable[to_find];
    }
    return "";
};

// Add to release_forms collection through the following steps:
// 1. match the high school name from the DocuSign release form to
//    the ID stored in the backend. If it does not exist school_id
//    is an empty string.
// 2. using the school_id, try to find the matching student
export const populateDocuSignFormsInBackend = async (
    docuSignForms: ImportedReleaseForm[],
    setDocuSignForms: React.Dispatch<
        React.SetStateAction<ImportedReleaseForm[]>
    >,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setLoading(true);
    const batch = writeBatch(db);

    // Query
    const schoolQuery = query(collection(db, "schools"));
    const studentQuery = query(collection(db, "students"));
    const releaseFormQuery = query(collection(db, "release_forms"));
    const schools: School[] = await getDocs(schoolQuery).then((schoolArray) => {
        return schoolArray.docs.map(
            (doc) =>
                ({
                    id: doc.id,
                    ...doc.data(),
                } as School)
        );
    });
    const students: Student[] = await getDocs(studentQuery).then(
        (studentArray) => {
            return studentArray.docs.map(
                (doc) =>
                    ({
                        id: doc.id,
                        ...doc.data(),
                    } as Student)
            );
        }
    );
    // Construct giant object with key as concat and value as student_id
    const lookuptable = students.reduce(
        (obj: { [index: string]: string }, student) => {
            const longkey =
                student.first.toLowerCase() +
                student.last.toLowerCase() +
                student.school;
            obj[longkey] = student.id;
            return obj;
        },
        {}
    );

    const existingReleaseFormIds = new Set();
    const releaseFormSnapshot = await getDocs(releaseFormQuery);
    releaseFormSnapshot.forEach((doc) => {
        existingReleaseFormIds.add(doc.id);
    });

    // Loop through release form only for new ones
    const formsToUpload = docuSignForms.filter(
        (form) => !existingReleaseFormIds.has(form.id)
    );

    formsToUpload.forEach((release_form_data) => {
        const student_first = release_form_data.student_first;
        const student_last = release_form_data.student_last;

        // First make a reference for the new doc
        const new_release_form_ref = doc(
            db,
            "release_forms",
            release_form_data.id
        );

        // Find the matching school_id
        const school_id = matchSchoolNameToID(
            release_form_data.school_name,
            schools
        );

        if (school_id === "") {
            console.log("No matching school found so skipping over");
            return;
        }

        // Find the matching student_id
        const student_id = findMatchingStudent(
            student_first,
            student_last,
            school_id,
            lookuptable
        );

        // Batch update the "release_form" field on the student record
        if (student_id !== "") {
            const student_to_update_ref = doc(db, "students", student_id);
            batch.update(student_to_update_ref, {
                release_form: release_form_data.id,
            });
        }

        // No need for school name anymore
        const {
            ["school_name"]: ignored,
            ...release_form_data_wo_school_name
        } = release_form_data;

        // Finally save the new release_form data to backend
        batch.set(new_release_form_ref, {
            ...release_form_data_wo_school_name,
            school: school_id,
            matched_student: student_id,
            uploaded_at: Timestamp.now(),
        });
    });

    try {
        await batch.commit();
        store.dispatch(setSuccess("Successfully uploaded release form info!"));
        setDocuSignForms([]);
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};
