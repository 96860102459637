import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import AddAccessCode from "../components/tournamentsetup/AddAccessCode";

import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

import {
    createEmptySelfScheduleEvents,
    parseDataAndSetState,
    populateSchoolAndTeamsInBackend,
    populateUsersAccessCodeInBackend,
    deleteAllCoaches,
} from "../utils/setup";
import {
    parseDocuSignDataAndSetState,
    populateDocuSignFormsInBackend,
} from "../utils/release_forms";
import { download_all } from "../utils/selfschedule";
import { School } from "../types/school";
import { ImportedReleaseForm } from "../types/form";
import { self_schedule_events } from "../utils/settings";
import { getAllSchools } from "../utils/school";
import { useAppSelector } from "../redux/hooks";

import CsvLoader from "../components/awardsslidescreator/csvLoader";
import Slides from "../components/awardsslidescreator/Slides";

export default function AwardsSlidesCreator() {
    const [csvData, setCsvData] = useState(null);
    const [excludeText, setExcludeText] = useState("");
    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    pb: 3,
                    px: 1,
                }}
            >
                <Container maxWidth="xl">
                    <Typography variant="h3" py="12pt" color="white">
                        Awards Slides Creator
                    </Typography>

                    <div>
                        <p>Instructions</p>
                        <ol>
                            <li>
                                Upload a csv file with format similar to{" "}
                                {/* <a href={csv}>this</a>. */}
                            </li>
                            <li>
                                Click "Load all events" to load the csv file.
                            </li>
                            <li>
                                Click "Sign in with Google" to authenticate.
                            </li>
                            <li>Either enter an existing presentation ID.</li>
                            <li>
                                Click "Generate Slides from CSV" to generate
                                slides.
                            </li>
                        </ol>
                        Enter column names to exclude:
                        <input
                            type="text"
                            name="exclude"
                            onChange={(event) =>
                                setExcludeText(event.target.value)
                            }
                        />
                        <CsvLoader
                            setCsvData={setCsvData}
                            excludeText={excludeText}
                        />
                        <p>{csvData ? "Data loaded" : "No data yet"}</p>
                        <Slides csvData={csvData} />
                    </div>
                    {/* <Button
                        variant="contained"
                        disabled={true}
                        color="error"
                        onClick={() => {
                            console.log("Removing all coaches!");
                        }}
                    >
                        Delete
                    </Button> */}
                </Container>
            </Box>
        </DashboardLayout>
    );
}
