import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import AddAccessCode from "../components/tournamentsetup/AddAccessCode";

import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

import {
    createEmptySelfScheduleEvents,
    parseDataAndSetState,
    populateSchoolAndTeamsInBackend,
    populateUsersAccessCodeInBackend,
    deleteAllCoaches,
} from "../utils/setup";
import {
    parseDocuSignDataAndSetState,
    populateDocuSignFormsInBackend,
} from "../utils/release_forms";
import { download_all } from "../utils/selfschedule";
import { School } from "../types/school";
import { ImportedReleaseForm } from "../types/form";
import { self_schedule_events } from "../utils/settings";
import { getAllSchools } from "../utils/school";
import { useAppSelector } from "../redux/hooks";
import { download_students_with_no_forms } from "../utils/setup";

export default function TournamentSetup() {
    const role = useAppSelector((state) => state.user.user.role);
    const [users, setUsers] = React.useState<{ [input: string]: string }[]>([]); // Sets up access code and not the actual user
    const [schoolsInfo, setSchoolsInfo] = React.useState<
        { [input: string]: string }[]
    >([]);
    const [docuSignForms, setDocuSignForms] = React.useState<
        ImportedReleaseForm[]
    >([]);

    const handleSchoolsFileChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.currentTarget.files) {
            const inputFile = e.currentTarget.files[0];
            parseDataAndSetState(inputFile, setSchoolsInfo);
        }
    };

    const handleUsersFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const inputFile = e.currentTarget.files[0];
            parseDataAndSetState(inputFile, setUsers);
        }
    };

    const handleDocuSignFileChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.currentTarget.files) {
            const inputFile = e.currentTarget.files[0];
            parseDocuSignDataAndSetState(inputFile, setDocuSignForms);
        }
    };

    const [schools, setSchools] = useState<School[]>([]);

    useEffect(() => {
        if (role === "admin") {
            getAllSchools().then((allSchools: School[] | undefined) => {
                if (allSchools !== undefined) {
                    setSchools(allSchools);
                }
            });
        }
    }, []);

    const [releaseFormUploadLoading, setReleaseFormUploadLoading] =
        useState(false);
    const [accessCodeUploadLoadiing, setAccessCodeUploadLoading] =
        useState(false);

    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    pb: 3,
                    px: 1,
                }}
            >
                <Container maxWidth="xl">
                    <Typography variant="h3" py="12pt" color="white">
                        Remove all coaches users and associated accounts
                    </Typography>

                    <Button
                        variant="contained"
                        disabled={true}
                        color="error"
                        onClick={() => {
                            console.log("Removing all coaches!");
                            deleteAllCoaches();
                        }}
                    >
                        Delete
                    </Button>
                    <Typography variant="h3" py="12pt" color="white">
                        Setup Self Schedule Events
                    </Typography>

                    <Button
                        variant="contained"
                        disabled={true}
                        onClick={() => {
                            console.log(
                                "Creating sign ups for self schedule events!"
                            );
                            createEmptySelfScheduleEvents();
                        }}
                    >
                        Setup {self_schedule_events.length} Self Schedule
                        Events!
                    </Button>

                    <Typography variant="h3" py="12pt" color="white">
                        Setup Teams
                    </Typography>

                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" component="label" disabled>
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={handleSchoolsFileChange}
                            />
                        </Button>

                        <Button
                            variant="contained"
                            disabled={schoolsInfo.length === 0}
                            onClick={() => {
                                console.log(
                                    "Working hard to setup up the tournament!"
                                );
                                populateSchoolAndTeamsInBackend(schoolsInfo);
                            }}
                        >
                            Setup tournament with {schoolsInfo.length} schools!
                        </Button>
                    </Stack>

                    <Typography variant="h3" py="12pt" color="white">
                        Download Self Schedule Sign Ups
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            download_all();
                        }}
                    >
                        Download
                    </Button>

                    <Typography variant="h3" py="12pt" color="white">
                        Download Students with No Release Forms
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            download_students_with_no_forms();
                        }}
                    >
                        Download
                    </Button>

                    <Typography variant="h3" py="12pt" color="white">
                        Add User Access Code
                    </Typography>
                    <AddAccessCode schools={schools} />

                    <Typography variant="h3" py="12pt" color="white">
                        Setup User Access Codes (Batch Upload)
                    </Typography>

                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" component="label">
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={handleUsersFileChange}
                            />
                        </Button>

                        <Button
                            variant="contained"
                            disabled={users.length === 0}
                            onClick={() => {
                                console.log(
                                    "Working hard to setup up the users code!"
                                );
                                const schoolQuery = query(
                                    collection(db, "schools")
                                );
                                getDocs(schoolQuery)
                                    .then((schoolArray) => {
                                        return schoolArray.docs.map(
                                            (doc) =>
                                                ({
                                                    id: doc.id,
                                                    ...doc.data(),
                                                } as School)
                                        );
                                    })
                                    .then((cleanedSchoolArray) => {
                                        populateUsersAccessCodeInBackend(
                                            users,
                                            cleanedSchoolArray,
                                            setUsers,
                                            setAccessCodeUploadLoading
                                        );
                                    });
                            }}
                        >
                            Setup access code for {users.length} users!
                        </Button>
                    </Stack>

                    <Typography variant="h3" py="12pt" color="white">
                        Upload Release Forms
                    </Typography>

                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" component="label">
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={handleDocuSignFileChange}
                            />
                        </Button>

                        <LoadingButton
                            variant="contained"
                            disabled={docuSignForms.length === 0}
                            loading={releaseFormUploadLoading}
                            onClick={() => {
                                populateDocuSignFormsInBackend(
                                    docuSignForms,
                                    setDocuSignForms,
                                    setReleaseFormUploadLoading
                                );
                            }}
                        >
                            Upload {docuSignForms.length} DocuSign Forms!
                        </LoadingButton>
                    </Stack>
                </Container>
            </Box>
        </DashboardLayout>
    );
}
