import Papa from "papaparse";
import React from "react";
import {
    collection,
    doc,
    writeBatch,
    setDoc,
    getDoc,
    query,
    getDocs,
    where,
} from "firebase/firestore";
import { db } from "../firebase";
import store from "../redux/store";
import { setError, setSuccess } from "../redux";
import { School } from "../types/school";
import { AccessCode } from "../types/authentication";
import { self_schedule_events } from "./settings";
import { getFunctions, httpsCallable } from "firebase/functions";
import { object } from "firebase-functions/v1/storage";

// Create "selfschedule" collection with document ids of self schedule
// event labels and create empty arrays for 6 slots
export const createEmptySelfScheduleEvents = async () => {
    const empty_slots = {
        slot1: [],
        slot2: [],
        slot3: [],
        slot4: [],
        slot5: [],
        slot6: [],
    };

    const batch = writeBatch(db);
    self_schedule_events.forEach((e) => {
        console.log(e.label);
        const new_self_schedule_event_ref = doc(db, "selfschedule", e.label);
        batch.set(new_self_schedule_event_ref, { ...empty_slots });
    });

    try {
        await batch.commit();
        console.log("Done!");
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    }
};

export const parseDataAndSetState = (
    inputFile: any,
    setSchool: React.Dispatch<
        React.SetStateAction<{ [input: string]: string }[]>
    >
) => {
    const allowedFiles = ["csv", "tsv"];

    const fileExtension = inputFile?.type.split("/")[1];
    if (allowedFiles.includes(fileExtension)) {
        Papa.parse(inputFile, {
            header: true,
            complete: function (results: any) {
                setSchool(results.data);
            },
        });
    } else {
        store.dispatch(setError("Setup file must be a csv or a tsv."));
    }
};

interface SelfScheduleEventSlots {
    [key: string]: number[];
}

export const populateSchoolAndTeamsInBackend = async (
    schoolSetupInfo: {
        [input: string]: string;
    }[]
) => {
    const batch = writeBatch(db);
    schoolSetupInfo.forEach((school_row) => {
        const num_of_teams = Number(school_row["# of Teams"]);
        const empty_self_schedule_slots = num_of_teams === 2 ? [-1, -1] : [-1];
        const empty_self_schedule_events = self_schedule_events.reduce(
            (obj, item) => {
                obj[item["label"]] = empty_self_schedule_slots;
                return obj;
            },
            {} as SelfScheduleEventSlots
        );
        const new_school_data = {
            name: school_row.School,
            state: school_row.State,
            payment:
                school_row.Payment === "Received"
                    ? "completed"
                    : "not processed",
            team_number:
                num_of_teams === 2
                    ? [school_row["Team # 1"], school_row["Team # 2"]]
                    : [school_row["Team # 1"]],
            self_schedule: empty_self_schedule_events,
        };
        const new_school_ref = collection(db, "schools");
        batch.set(doc(new_school_ref), { ...new_school_data });
    });

    try {
        await batch.commit();
        console.log("Done!");
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    }
};

export const populateUsersAccessCodeInBackend = async (
    usersCodeInfo: {
        [input: string]: string;
    }[],
    schools: School[],
    setUsers: React.Dispatch<
        React.SetStateAction<{ [input: string]: string }[]>
    >,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setLoading(true);

    const batch = writeBatch(db);
    usersCodeInfo.forEach((user_row) => {
        const new_user_data = {
            email: user_row.email.toLowerCase(),
            code: user_row.code,
            school: schools.find((e) => e.name === user_row.school)?.id, //school name must exactly match as the on in database
        };
        const new_user_code_ref = doc(
            db,
            "access_code",
            user_row.email.toLowerCase()
        );
        batch.set(new_user_code_ref, { ...new_user_data });
    });

    try {
        await batch.commit();
        store.dispatch(setSuccess("Successfully added access codes!"));
        setUsers([]);
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

const functions = getFunctions();
const deleteAndRemoveAllCoachesCF = httpsCallable(
    functions,
    "deleteAndRemoveAllCoaches"
);

export const deleteAllCoaches = async () => {
    try {
        let result = (await deleteAndRemoveAllCoachesCF({})) as {
            data: { message: string };
        };
        let resp: { message: string } = result.data;
        store.dispatch(setSuccess(resp.message));
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
    }
};

export const addAccessCode = async (
    new_code: AccessCode,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        // Check if a document with the same ID (email) already exists
        const docRef = doc(db, "access_code", new_code.email);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            throw new Error("This email was already issued an access code.");
        }

        // Add the document with the email as the ID
        await setDoc(docRef, {
            ...new_code,
        });
        store.dispatch(setSuccess("Access code added successfully!"));
        setOpen(false);
    } catch (error: any) {
        let message = "";
        switch (error.code) {
            default: {
                message = error.message;
                break;
            }
        }
        store.dispatch(setError(message));
        console.error(error);
        throw error;
    }
};

export const download_students_with_no_forms = async () => {
    // TODO: Error handling
    const schoolsQuery = query(collection(db, "schools"));

    const schools = await getDocs(schoolsQuery).then((schoolArray) => {
        return schoolArray.docs.map((doc) => ({
            name: doc.data().name,
            id: doc.id,
        }));
    });

    const lookuptable = schools.reduce(
        (obj: { [index: string]: string }, school) => {
            obj[school.id] = school.name;
            return obj;
        },
        {}
    );

    const studentQuery = query(
        collection(db, "students"),
        where("release_form", "==", "")
    );
    const querySnapshot = await getDocs(studentQuery);
    const final_data: any[] = [];

    querySnapshot.forEach((doc) => {
        const data = doc.data();
        final_data.push({
            first_name: data.first,
            last_name: data.last,
            school: lookuptable[data.school],
        });
        final_data.push();
    });

    if (final_data.length === 0) {
        store.dispatch(
            setError("All students have completed their release forms!")
        );
        return;
    }

    const csvdata = csvmaker(final_data);
    download(csvdata);
};

export const download = function (data: any) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a");

    // Passing the blob downloading url
    a.setAttribute("href", url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", "students_with_no_forms.csv");

    // Performing a download with click
    a.click();
};

export const csvmaker = function (data: { [key: string]: string }[]) {
    // Empty array for storing the values
    let csvRows = [];

    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    data.forEach((row) => {
        const values = Object.values(row).join(",");
        csvRows.push(values);
    });

    // Returning the array joining with new line
    return csvRows.join("\n");
};
