import { User } from "../../types/users";

export type Order = "asc" | "desc";

type HeadCell = {
    disablePadding: boolean;
    id: keyof User;
    label: string;
    numeric: boolean;
    sortable: boolean;
};

export const headCells: readonly HeadCell[] = [
    {
        id: "role",
        numeric: false,
        disablePadding: false,
        label: "Role",
        sortable: true,
    },
    {
        id: "first_name",
        numeric: false,
        disablePadding: true,
        label: "First",
        sortable: true,
    },
    {
        id: "last_name",
        numeric: false,
        disablePadding: true,
        label: "Last",
        sortable: true,
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
        sortable: true,
    },
    {
        id: "phone_number",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
        sortable: false,
    },
    {
        id: "school_name",
        numeric: false,
        disablePadding: true,
        label: "School",
        sortable: true,
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
